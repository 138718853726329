// tslint:disable:max-line-length
export default {
  'global.users': 'Benutzer',
  'global.roles': 'Rollen',
  'global.search': 'Suchen',
  'global.create': 'Erstellen',
  'global.discard': 'Verwerfen',
  'global.cancel': 'Abbrechen',
  'global.confirm': 'Bestätigen',
  'global.swissFrancs': 'CHF',
  'global.oClock': 'Uhr',
  'global.delete': 'Löschen',
  'weekdays.short0': 'So',
  'global.dataReset': 'Daten wurden zurückgesetzt',
  'weekdays.short1': 'Mo',
  'weekdays.short2': 'Di',
  'weekdays.short3': 'Mi',
  'weekdays.short4': 'Do',
  'weekdays.short5': 'Fr',
  'weekdays.short6': 'Sa',
  'weekdays.monday': 'Montag',
  'weekdays.tuesday': 'Dienstag',
  'weekdays.wednesday': 'Mittwoch',
  'weekdays.thursday': 'Donnerstag',
  'weekdays.friday': 'Freitag',
  'weekdays.saturday': 'Samstag',
  'weekdays.sunday': 'Sonntag',
  'navigation.newTask': 'Neuer Auftrag',
  'navigation.search': 'Suchen',
  'navigation.dispo': 'Aufgaben disponieren',
  'navigation.orders': 'Bestellungen',
  'navigation.customers': 'Kunden & Detailhändler',
  'navigation.users': 'Benutzer & Rollen',
  'navigation.analytics': 'Statistik & Auswertungen',
  'navigation.settings': 'Betriebseinstellungen',
  'navigation.signout': 'Abmelden',
  'navigation.openDeliveries': '({0}) Offene Aufgaben',
  'navigation.resetData': 'Testdaten zurücksetzten',
  'navigation.username': 'Angemeldet als {0}',
  'users.new': 'Neuer Benutzer',
  'users.name': 'Name',
  'users.firstName': 'Vorname',
  'users.role': 'Rolle',
  'users.roles': 'Rollen',
  'users.contact': 'Kontakt',
  'users.addRole': 'Rolle hinzufügen',
  'users.assignedTransports': 'Zugewiesene Transporte',
  'users.lastActive': 'Zuletzt aktiv',
  'users.tasks': 'Tasks erfüllt',
  'users.street': 'Strasse',
  'users.city': 'Ort',
  'users.phone': 'Telefon',
  'users.email': 'E-Mail',
  'users.password': 'Neues Passwort',
  'users.cockpitAccess': 'Cockpit Zugang',
  'users.courierAccess': 'Kurier App Zugang',
  'users.cockpit': 'Cockpit',
  'users.courierApp': 'Kurier App',
  'users.generateToken': 'Neues Anmeldetoken generieren',
  'users.deleteUser': 'Benutzer löschen',
  'users.checkDeleteUser': 'Willst du den Benutzer {0} wirklich löschen?',
  'users.deleteRole': 'Rolle löschen',
  'users.checkDeleteRole': 'Willst du die Rolle {0} wirklich löschen?',
  'users.optionalDispo': 'Optionale Dispo',
  'users.isCockpitAdmin': 'Optionale Dispo',
  'roles.name': 'Name',
  'roles.add': 'Neue Rolle',
  'roles.couriers': 'Kuriere',
  'roles.addCourier': 'Kurier hinzufügen',
  'roles.addZone': 'HLD Zone hinzufügen',
  'roles.autoDispose': 'Automatische Abwicklung von HLD Lieferungen',
  'roles.monitorPickups': 'Bei HLD Aufgabestellen abholen',
  'roles.monitorDropoffs': 'In HLD Zonen ausliefern',
  'login.email': 'E-Mail',
  'login.password': 'Passwort',
  'login.login': 'Anmelden',
  'error.invalidCredentials': 'Email oder Passwort falsch. Bitte versuche es nochmals!',
  'error.timeout': 'Du wurdest wegen Inaktivität automatisch abgemeldet. Bitte melde dich neu an!',
  'error.noAuthenticatedUserSummary': 'Benutzerdaten konnten nicht geladen werden. Bitte versuche es nochmals!',
  'error.noConnection': 'Momentan kann keine Verbindung mit dem Server hergestellt werden! Bitte versuche es später erneut!',
  'error.webserviceError': 'Auf dem Webservice ist ein Fehler aufgetreten:',
  'error.unsupportedBrowser': 'Das viaVelo Cockpit ist für Google Chrome optimiert. Unterstützung in anderen Browser kann eingeschränkt oder fehlerhaft sein. Bitte installiere Google Chrome und verwende diesen Browser um das Cockpit zu bedienen!',
  'deliveries.order': 'Bestellung',
  'deliveries.remove': 'Bestellung löschen',
  'deliveries.removeTitle': 'Bestellung {0} löschen',
  'deliveries.removeConfirm': 'Ja, ich möchte die Bestellung {0} unwiderruflich löschen',
  'deliveries.allDisposed': 'Alle Transporte sind disponiert!',
  'deliveries.transports': 'Transporte',
  'deliveries.createOrder': 'Neue Bestellung',
  'deliveries.customer': 'Auftraggeber',
  'deliveries.price': 'Preis',
  'deliveries.dispo': 'Dispo',
  'deliveries.shippingdate': 'Lieferdatum',
  'deliveries.inProgress': 'In Bearbeitung',
  'deliveries.todo': 'Verfügbar',
  'deliveries.upcoming': 'Eingeplant',
  'deliveries.noTransports': 'Keine Transporte',
  'deliveries.disposeManual': 'Nicht zugewiesen',
  'deliveries.disposeAuto': 'Automatisch disponieren ({0})',
  'deliveries.noRole': 'Keine Rolle vorhanden',
  'deliveries.removeAssignee': 'Zuweisung entfernen',
  'deliveries.assignTo': '{0} zuweisen',
  'deliveries.assignToRole': 'Rolle {0} zuweisen',
  'deliveries.missingItems': 'Verlorene Lieferobjekte',
  'deliveries.cancelDeliveryItem': 'Lieferobjekt aus Bestellung entfernen',
  'deliveries.canceledDeliveryItemState': 'storniert',
  'deliveries.initialTime': 'Geplant:',
  'deliveries.confirmationTime': 'Erledigt:',
  'deliveries.order_source_cockpit': 'Bestellung via Cockpit',
  'deliveries.order_source_api': 'Bestellung via API',
  'deliveries.order_source_customer': 'Bestellung via Kunde',
  'deliveries.order_source_courier': 'Bestellung via Kurier',
  'deliveries.order_source_unknown': 'Bestellungsherkunft unbekannt',
  'deliveriesOverview.orders': 'Bestellungen',
  'deliveries.dispenseFrontDoor': 'Vor Haustüre stellen',
  'deliveries.dispenseApartmentDoor': 'Vor Wohnungstüre stellen ({0})',
  'deliveriesOverview.order': 'Bestellung',
  'deliveriesOverview.customer': 'Auftraggeber',
  'deliveriesOverview.progress': 'Fortschritt',
  'deliveriesOverview.noDeliveries': 'Keine Aufträge für das ausgewählte Datum vorhanden',
  'deliveriesOverview.deliveryStatus.UNPLANNED': 'Offen',
  'deliveriesOverview.deliveryStatus.IN_PROGRESS': 'In Bearbeitung',
  'deliveriesOverview.deliveryStatus.FULFILLED': 'Abgeschlossen',
  'orderDraft.pickup': 'Pickup',
  'orderDraft.dropOff': 'Drop Off',
  'orderDraft.removeCondition': 'Ort löschen',
  'orderDraft.addCondition': 'Ort hinzufügen',
  'orderDraft.deliveryItem': 'Lieferobjekt',
  'orderDraft.locationName': 'Name',
  'customers.customers': 'Kunden',
  'customers.retailer': 'HLD Aufgabestelle',
  'customers.newCustomer': 'Neuer Kunde',
  'customers.createNewCustomer': 'Neuer Kunde erfassen',
  'customers.name': 'Name',
  'customers.street': 'Strasse',
  'customers.city': 'Ort',
  'customers.phone': 'Telefon',
  'customers.address': 'Adresse',
  'customers.note': 'Bemerkung zum Kunden (nur für Betreiber sichtbar)',
  'customers.deleteCustomer': 'Kunde löschen',
  'customers.checkDeleteCustomer': 'Willst du den Kunden {0} wirklich löschen?',
  'customers.newRetailer': 'Neuer Detailhändler',
  'customers.deleteRetailer': 'Detailhändler löschen',
  'customers.checkDeleteRetailer': 'Willst du den Detailhändler {0} wirklich löschen?',
  'customers.retailerIndividualPrice': 'Preis pro Lieferung',
  'customers.retailerIndividualPriceEnabled': 'Individueller Preis für HLD-Lieferungen hinterlegen',
  'customers.qrCodes': 'QR-Codes für Taschen',
  'customers.bagPromotions': 'Gratis-Taschen',
  'customers.prefix': 'Präfix',
  'customers.sequence': 'Start der Sequenz',
  'customers.quantity': 'Anzahl Codes',
  'customers.add': 'Hinzufügen',
  'customers.allInclusive': 'Unlimitiertes Abo',
  'customers.allInclusiveHelper': 'Kunden die die Option "Unlimitiertes Abo" aktiviert haben können Lieferungen und Zusatzdienstleistungen unbeschränkt gratis nutzen.',
  'customers.phoneNumberReadOnlyHelper': 'Die Telefonnummern von Kunden mit einem Account können nicht editiert werden.',
  'customers.individualPrice': 'Individueller Preis',
  'settings.information': 'Information',
  'settings.company': 'Betreiber',
  'settings.deliverySettings': 'Liefereinstellungen',
  'settings.adInfos': 'Lokales Sponsoring',
  'settings.adInfosIntro': 'Aktuelle Einstellungen zum lokalen Sponsoring verwalten.',
  'settings.adInfosImage': 'Werbebild',
  'settings.adInfosSelectImage': 'Bild auswählen',
  'settings.adInfosUploadImage': 'Hochladen',
  'settings.adInfosUploading': 'Bild wird hochgeladen...',
  'settings.adInfosNoImage': 'Kein lokales Sponsoring vorhanden. In der App wird ein Platzhalter angezeigt.',
  'settings.adInfosImageDelete': 'Willst du das Werbebild wirklich löschen? In den Kunden-Apps wird danach ein Platzhalter angezeigt.',
  'settings.adInfosHelper': 'Das Werbebild für das lokale Sponsoring  wird nach dem Abschluss der Bestellung in den Kunden-Apps angezeigt.',
  'settings.adInfosGuide': 'Format: PNG/JPG / Auflösung: 1400 × 1000 px / Dateigrösse: max. 3 MB',
  'settings.dispo': 'Zentrale / Disposition',
  'settings.dispoHelper': 'Automatisch verarbeitete Bestellungen werden mit dieser Einstellung vom System über die Zentrale disponiert.',
  'settings.enableDispo': 'Aufträge über Zentrale abwickeln (Aufgabeort ⇢ Zentrale ⇢ Zielort)',
  'settings.dispoAddress': 'Adresse der Zentrale',
  'settings.zonesDefined': 'Zonen definiert ({0})',
  'settings.businessHours': 'Öffnungszeiten',
  'settings.businessHoursHelper': 'Innerhalb der festgelegten Öffnungszeiten abzüglich der garantierten  Lieferzeit können über die Kunden App Bestellungen ausgelöst werden. Diese Einstellung wird für alle neuen Bestellungen angewendet. Bereits in Auftrag gegebene Bestellungen sind von dieser Einstellung nicht betroffen.',
  'settings.businessHour': 'Aufgabezeit',
  'settings.closed': 'Geschlossen',
  'settings.addWeekday': 'Wochentag hinzufügen',
  'settings.addBusinessHour': 'Aufgabezeit hinzufügen',
  'settings.removeBusinessHour': 'Willst du diese Aufgabezeit wirklich löschen?',
  'settings.deliveryTime': 'Lieferfenster',
  'settings.guaranteedDeliveryTime': 'Garantierte Lieferung innerhalb von',
  'settings.hours': 'Stunden',
  'settings.deliveryWindowRange': 'Lieferung zwischen',
  'settings.addDeliveryWindow': 'Lieferfenster hinzufügen',
  'settings.removeDeliveryWindow': 'Willst du dieses Lieferfenster wirklich löschen?',
  'settings.deliveryTimeHelper': 'Garantierte Lieferzeit und verfügbare Lieferfenster innerhalb der Abgabezeiten definieren. Diese Optionen stehen in der Kunden App für die Auswahl der gewünschten Lieferzeit zur Verfügung.',
  'settings.noDeliveryWindowDefined': 'Keine Lieferfenster definiert',
  'settings.deliveryDispense': 'Abgabeoptionen',
  'settings.deliveryDispenseHelper': 'Verfügbare Abgabeoptionen und Einstellungen definieren.',
  'settings.deliveryDispenseOptions': 'Verfügbare Abgabeoptionen',
  'settings.deliveryDispenseMultiSelection': 'Auswahl von mehreren Optionen pro Bestellung erlauben',
  'settings.deliveryDispenseFrontDoor': 'Vor Haustür stellen',
  'settings.deliveryDispenseApartmentDoor': 'Vor Wohnungstür stellen',
  'settings.deliveryDispenseCustomOption': 'Freitext Benutzereingaben zulassen',
  'settings.orderSettings': 'Liefertypen',
  'settings.hld': 'HLD Lieferungen',
  'settings.deliveryPrice': 'Preis pro Lieferung',
  'settings.deliveryArea': 'Liefergebiet',
  'settings.EditDeliveryArea': 'Liefergebiet bearbeiten',
  'settings.deliveryAreaInformation': 'Aktuelles Liefergebiet anzeigen und Anleitung zum editieren aufrufen.',
  'settings.deliveryAreaShowZoomed': 'Liefergebiet vergrössern',
  'settings.deliveryAreaEditGuide.step1': 'Liefergebiet auf map.geo.admin.ch bearbeiten',
  'settings.deliveryAreaEditGuide.step1Link': 'Hier Karte öffnen',
  'settings.deliveryAreaEditGuide.step2': '«Zeichnen & Messen auf der Karte» wählen',
  'settings.deliveryAreaEditGuide.step3': 'Liefergebiet nach wünschen anpassen',
  'settings.deliveryAreaEditGuide.step4': '«Zurück / Zeichnen beenden» sobald Liefergebiet in Ordnung ist',
  'settings.deliveryAreaEditGuide.step5': '«Teilen» Link selektieren',
  'settings.deliveryAreaEditGuide.step6': '«Link kopieren» und per E-Mail an {0} senden!',
  'settings.deliveryAreaEditGuide.hintTitle': 'Zu beachten beim Editieren',
  'settings.deliveryAreaEditGuide.hint1': 'Zonen/Flächen dürfen sich nicht überlagern, müssen jedoch angrenzend sein',
  'settings.deliveryAreaEditGuide.hint2': 'Zonen/Flächen müssen eine entsprechende Bezeichnung haben',
  'settings.amountOfDeliveryObjects': 'Anzahl Lieferobjekte pro Lieferung',
  'settings.deliveryObjectBags': 'Taschen',
  'settings.supplementalService': 'Zusatzdienstleistungen',
  'settings.supplementalServiceName': 'Bezeichnung',
  'settings.addSupplementalService': 'Zusatzdienstleistung hinzufügen',
  'settings.removeSupplementalService': 'Willst du diese Zusatzdienstleistung wirklich löschen?',
  'settings.noSupplementalServiceDefined': 'Keine Zusatzdienstleistung definiert',
  'settings.companyName': 'Name',
  'settings.companyPhone': 'Telefon',
  'settings.companyTermsUrl': 'Link zu den AGB',
  'settings.companyTermsUrlMustHaveHttps': 'Link muss mit http:// oder https:// starten',
  'settings.companyNoLogo': 'Kein Logo',
  'settings.companyText': 'Beschreibung',
  'settings.deliveryNotifications': 'Benachrichtigungen',
  'settings.deliveryNotifications.courier': 'Bei Bestellungseingang Push-Nachricht in Kurier-App auslösen',
  'analytics.moreStatisticsHintTitle': 'Brauchst du mehr Statistiken?',
  'analytics.moreStatisticsHintText': 'Im Looker Studio findest du mehr Statistiken. Für Setup, Fragen oder Hilfe beim Einrichten der Abfragen melde dich an info@viavelo.ch',
  'analytics.moreStatisticsHintButton': 'Looker Studio öffnen',
  'analytics.filter': 'Filter',
  'analytics.today': 'Heute',
  'analytics.dateRange': '{0} bis {1}',
  'analytics.analyticsHelper': 'Es werden nur abgeschlossene HLD Bestellungen im ausgewählten Zeitraum von für die Auswertung berücksichtigt.',
  'analytics.retailerName': 'Aufgabestelle',
  'analytics.street': 'Strasse',
  'analytics.city': 'Ort',
  'analytics.totalOrders': 'HLD Bestellungen',
  'analytics.totalOrdersHelper': 'Alle abgeschlossenen HLD Bestellungen',
  'analytics.totalDeliveries': 'HLD Lieferungen',
  'analytics.totalDeliveriesHelper': 'Alle abgeschlossenen HLD Lieferungen',
  'freeDeliveryItem.label': 'QR-Code Beschriftung',
  'freeDeliveryItem.newFreeDeliveryItems': 'Neue Gratis-Taschen erfassen',
  'freeDeliveryItem.noItems': 'Keine Gratis-Taschen vorhanden',
  'freeDeliveryItem.prefix': 'Präfix',
  'freeDeliveryItem.minimumFieldWidth': 'Anzahl numerische Stellen',
  'freeDeliveryItem.start': 'Start der Sequenz',
  'freeDeliveryItem.quantity': 'Anzahl Codes'
}
