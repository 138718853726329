// tslint:disable:max-line-length
export default {
  'global.users': 'Utilisateur',
  'global.roles': 'Rôles',
  'global.search': 'Rechercher',
  'global.create': 'Créer',
  'global.discard': 'Ignorer',
  'global.cancel': 'Annuler',
  'global.confirm': 'Confirmer',
  'global.swissFrancs': 'CHF',
  'global.oClock': 'Heure',
  'global.delete': 'Supprimer',
  'weekdays.short0': 'Di',
  'global.dataReset': 'Les données ont été réinitialisées',
  'weekdays.short1': 'Lu',
  'weekdays.short2': 'Ma',
  'weekdays.short3': 'Me',
  'weekdays.short4': 'Je',
  'weekdays.short5': 'Ve',
  'weekdays.short6': 'Sa',
  'weekdays.monday': 'Lundi',
  'weekdays.tuesday': 'Mardi',
  'weekdays.wednesday': 'Mercredi',
  'weekdays.thursday': 'Jeudi',
  'weekdays.friday': 'Vendredi',
  'weekdays.saturday': 'Samedi',
  'weekdays.sunday': 'Dimanche',
  'navigation.newTask': 'Nouvelle tâche',
  'navigation.search': 'Rechercher',
  'navigation.dispo': 'Distribuer la tâche',
  'navigation.orders': 'Commandes',
  'navigation.customers': 'Clients & Commerces',
  'navigation.users': 'Utilisateurs & Rôles',
  'navigation.analytics': 'Statistiques & Évaluations',
  'navigation.settings': 'Réglages',
  'navigation.signout': 'Se déconnecter',
  'navigation.openDeliveries': '({0}) tâches ouvertes',
  'navigation.resetData': 'Réinitialiser les données de test',
  'navigation.username': 'Connecté en tant que {0}',
  'users.new': 'Nouvel utilisateur',
  'users.name': 'Nom',
  'users.firstName': 'Prénom',
  'users.role': 'Rôle',
  'users.roles': 'Rôles',
  'users.contact': 'Contact',
  'users.addRole': 'Ajouter un rôle',
  'users.assignedTransports': 'Transports attribués',
  'users.lastActive': 'Dernière activité',
  'users.tasks': 'Tâche effectuée',
  'users.street': 'Rue',
  'users.city': 'Lieu',
  'users.phone': 'Téléphone',
  'users.email': 'E-Mail',
  'users.password': 'Nouveau mot de passe',
  'users.cockpitAccess': 'Accès Cockpit',
  'users.courierAccess': 'Accès App Coursier',
  'users.cockpit': 'Cockpit',
  'users.courierApp': 'App Coursier',
  'users.generateToken': 'Générer un nouveau code QR',
  'users.deleteUser': 'Supprimer utilisateur',
  'users.checkDeleteUser': 'Veux-tu vraiment supprimer l’utilisateur {0} ?',
  'users.deleteRole': 'Supprimer rôle',
  'users.checkDeleteRole': 'Veux-tu vraiment supprimer le rôle {0} ?',
  'users.optionalDispo': 'Optionale Dispo',
  'users.isCockpitAdmin': 'Administrateur',
  'roles.name': 'Nom',
  'roles.add': 'Nouveau rôle',
  'roles.couriers': 'Coursiers',
  'roles.addCourier': 'Ajouter coursier',
  'roles.addZone': 'Ajouter zone HLD',
  'roles.autoDispose': 'Traitement automatique des livraisons HLD',
  'roles.monitorPickups': 'A aller chercher aux points de dépot HLD',
  'roles.monitorDropoffs': 'A livrer dans les zones HLD',
  'login.email': 'Email',
  'login.password': 'Mot de passe',
  'login.login': 'Se connecter',
  'error.invalidCredentials': 'Email ou mot de passe incorrect. Merci de réessayer!',
  'error.timeout': 'Tu as été automatiquement déconnecté pour cause d’inactivité. Merci de te reconnecter!',
  'error.noAuthenticatedUserSummary': 'Les données utilisateur n’ont pas pu être chargées. Bitte versuche es nochmals!',
  'error.noConnection': 'Aucune connexion avec le serveur ne peut être établie pour le moment. Merci de réessayer plus tard!',
  'error.webserviceError': 'Une erreur s’est produite sur le webservice:',
  'error.unsupportedBrowser': 'Le Cockpit viaVelo est optimisé pour Google Chrome. L’utilisation sur d’autres navigateurs peut être limitée ou causer des erreurs. Merci d’installer Google Chrome et d’utiliser ce navigateur pour le Cockpit!',
  'deliveries.order': 'Commande',
  'deliveries.remove': 'Effacer la commande',
  'deliveries.removeTitle': 'Effacer la commande {0}',
  'deliveries.removeConfirm': 'Oui, je souhaite supprimer la commande {0} de manière définitive',
  'deliveries.allDisposed': 'Tous les transports sont distribués!',
  'deliveries.transports': 'Transports ',
  'deliveries.createOrder': 'Nouvelle commande',
  'deliveries.customer': 'Client',
  'deliveries.price': 'Prix',
  'deliveries.dispo': 'Dispo',
  'deliveries.shippingdate': 'Date de livraison',
  'deliveries.inProgress': 'En cours de traitement',
  'deliveries.todo': 'Disponible',
  'deliveries.upcoming': 'Planifié',
  'deliveries.noTransports': 'Pas de transport',
  'deliveries.disposeManual': 'Non attribué',
  'deliveries.disposeAuto': 'Distribuer automatiquement ({0})',
  'deliveries.noRole': 'Aucun rôle disponible',
  'deliveries.removeAssignee': 'Retirer l’attribution',
  'deliveries.assignTo': 'Attribuer à {0} ',
  'deliveries.assignToRole': 'Attribuer rôle à {0}',
  'deliveries.missingItems': 'Objets de livraison perdus',
  'deliveries.cancelDeliveryItem': 'Retirer l’objet de la commande',
  'deliveries.canceledDeliveryItemState': 'Annulé',
  'deliveries.initialTime': 'Planifié:',
  'deliveries.confirmationTime': 'Effectué:',
  'deliveries.order_source_cockpit': 'Bestellung via Cockpit',
  'deliveries.order_source_api': 'Bestellung via API',
  'deliveries.order_source_customer': 'Bestellung via Kunde',
  'deliveries.order_source_courier': 'Bestellung via Kurier',
  'deliveries.order_source_unknown': 'Bestellungsherkunft unbekannt',
  'deliveriesOverview.orders': 'Commandes',
  'deliveries.dispenseFrontDoor': 'Déposer devant la porte de la maison',
  'deliveries.dispenseApartmentDoor': 'Déposer devant la porte de l’appartement ({0})',
  'deliveriesOverview.order': 'Commande',
  'deliveriesOverview.customer': 'Client',
  'deliveriesOverview.progress': 'Progrès',
  'deliveriesOverview.noDeliveries': 'Aucune commande disponible pour la date sélectionnée',
  'deliveriesOverview.deliveryStatus.UNPLANNED': 'Ouvert',
  'deliveriesOverview.deliveryStatus.IN_PROGRESS': 'En cours de traitement',
  'deliveriesOverview.deliveryStatus.FULFILLED': 'Terminé',
  'orderDraft.pickup': 'Pickup',
  'orderDraft.dropOff': 'Drop Off',
  'orderDraft.removeCondition': 'Effacer lieu',
  'orderDraft.addCondition': 'Ajouter lieu',
  'orderDraft.deliveryItem': 'Objet de livraison',
  'orderDraft.locationName': 'Nom',
  'customers.customers': 'Client',
  'customers.retailer': 'Lieu de dépôt HLD',
  'customers.newCustomer': 'Nouveau client',
  'customers.createNewCustomer': 'Entrer nouveau client',
  'customers.name': 'Nom',
  'customers.street': 'Rue',
  'customers.city': 'Lieu',
  'customers.phone': 'Téléphone',
  'customers.address': 'Adresse',
  'customers.note': 'Remarque concernant le client (visible uniquement par l’exploitant)',
  'customers.deleteCustomer': 'Supprimer client',
  'customers.checkDeleteCustomer': 'Veux-tu vraiment supprimer le client {0} ?',
  'customers.newRetailer': 'Nouveau commerce',
  'customers.deleteRetailer': 'Supprimer commerce',
  'customers.checkDeleteRetailer': 'Veux-tu vraiment supprimer le commerce {0} ?',
  'customers.retailerIndividualPrice': 'Prix par livraison',
  'customers.retailerIndividualPriceEnabled': 'Entrer le prix individuel pour les livraisons HLD',
  'customers.qrCodes': 'Codes QR pour sacs',
  'customers.bagPromotions': 'Sacs gratuit',
  'customers.prefix': 'Préfixe',
  'customers.sequence': 'Début de la séquence',
  'customers.quantity': 'Nombre de codes',
  'customers.add': 'Ajouter',
  'customers.allInclusive': 'Abonnement illimité',
  'customers.allInclusiveHelper': 'Les clients qui ont activé l’option "Abonnement illimité" peuvent utiliser gratuitemenet et en illimité les livraisons et services supplémentaires. ',
  'customers.phoneNumberReadOnlyHelper': 'Les numéros de téléphone des clients ayant un compte ne peuvent pas être modifiés.',
  'customers.individualPrice': 'Prix individuel',
  'settings.information': 'Information',
  'settings.company': 'Exploitant',
  'settings.deliverySettings': 'Paramètres de livraison',
  'settings.adInfos': 'Parrainage local',
  'settings.adInfosIntro': 'Administrer les paramètres actuels du parrainage local.',
  'settings.adInfosImage': 'Image publicitaire',
  'settings.adInfosSelectImage': 'Sélectionner l’image',
  'settings.adInfosUploadImage': 'Télécharger',
  'settings.adInfosUploading': 'Image en cours de téléchargement...',
  'settings.adInfosNoImage': 'Aucun parrainage local n’est disponible. Un placeholder est affiché dans l’application.',
  'settings.adInfosImageDelete': 'Tu veux vraiment supprimer l’image? Un placeholder est ensuite affiché dans les apps des clients.',
  'settings.adInfosHelper': 'L\'image publicitaire du parrainage local est affichée dans les applications client une fois la commande terminée.\n',
  'settings.adInfosGuide': 'Format: PNG/JPG / Résolution: 1400 × 1000 px / Taille du fichier: max. 3 MB',
  'settings.dispo': 'Centrale / Disposition',
  'settings.dispoHelper': 'Avec ce réglage, les commandes traitées automatiquement seront distribuées par le système via la centrale.',
  'settings.enableDispo': 'Traiter les tâches via la centrale (Lieu de dépôt ⇢ Centrale ⇢ Destination)',
  'settings.dispoAddress': 'Adresse de la centrale',
  'settings.zonesDefined': 'Zones définies ({0})',
  'settings.businessHours': 'Heures d’ouverture',
  'settings.businessHoursHelper': 'Pendant les heures d’ouverture définies, moins le délai de livraison garanti, les commandes peuvent être passées via l’application client. Ce paramètre est appliqué à toutes les nouvelles commandes. Les commandes déjà passées ne sont pas concernées par ce réglage.',
  'settings.businessHour': 'Heure de dépôt',
  'settings.closed': 'Fermé',
  'settings.addWeekday': 'Ajouter un jour de la semaine',
  'settings.addBusinessHour': 'Ajouter une heure de dépôt',
  'settings.removeBusinessHour': 'Veux-tu vraiment supprimer cette heure de dépôt?',
  'settings.deliveryTime': 'Fenêtre de livraison',
  'settings.guaranteedDeliveryTime': 'Livraison garantie dans un délai de',
  'settings.hours': 'heures',
  'settings.deliveryWindowRange': 'Livraison entre',
  'settings.addDeliveryWindow': 'Ajouter délai de livraison',
  'settings.removeDeliveryWindow': 'Veux-tu vraiment supprimer ce délai de livraison ?',
  'settings.deliveryTimeHelper': 'Définir le délai de livraison garanti et la fenêtre de livraison disponible dans les heures de livraison. Ces options sont disponibles dans l’application client pour le choix de la période de livraison souhaitée.',
  'settings.noDeliveryWindowDefined': 'Aucun délai de livraison défini',
  'settings.deliveryDispense': 'Options de livraison',
  'settings.deliveryDispenseHelper': 'Définir les options de livraison et les réglages disponibles',
  'settings.deliveryDispenseOptions': 'Options de livraison disponibles',
  'settings.deliveryDispenseMultiSelection': 'Autoriser le choix de plusieurs options par commande',
  'settings.deliveryDispenseFrontDoor': 'Déposer devant la porte de la maison',
  'settings.deliveryDispenseApartmentDoor': 'Déposer devant la porte de l’appartement',
  'settings.deliveryDispenseCustomOption': 'Permettre la saisie de texte libre par l’utilisateur',
  'settings.orderSettings': 'Type de livraison',
  'settings.hld': 'Livraisons HLD',
  'settings.deliveryPrice': 'Prix par livraison',
  'settings.deliveryArea': 'Zone de livraison',
  'settings.EditDeliveryArea': 'Editer la zone de livraison',
  'settings.deliveryAreaInformation': 'Afficher la zone de livraison actuelle et montrer les instructions pour l’édition.',
  'settings.deliveryAreaShowZoomed': 'Élargir la zone de prestation',
  'settings.deliveryAreaEditGuide.step1': 'Modifier la zone de livraison sur map.geo.admin.ch',
  'settings.deliveryAreaEditGuide.step1Link': 'Ouvrir la carte ici',
  'settings.deliveryAreaEditGuide.step2': 'Sélectionner «Dessiner et mesurer sur la carte»',
  'settings.deliveryAreaEditGuide.step3': 'Ajuster la zone de livraison selon les besoins',
  'settings.deliveryAreaEditGuide.step4': '«Retour / Terminer le dessin» dès que la zone de livraison est ok',
  'settings.deliveryAreaEditGuide.step5': 'Sélectionner le lien «Partager»',
  'settings.deliveryAreaEditGuide.step6': '«Copier le lien» et l’envoyer par e-mail à {0}!',
  'settings.deliveryAreaEditGuide.hintTitle': 'A observer lors de l’édition',
  'settings.deliveryAreaEditGuide.hint1': 'Les zones/surfaces ne peuvent pas se chevaucher mais doivent être adjacentes',
  'settings.deliveryAreaEditGuide.hint2': 'Les zones/surfaces doivent avoir une désignation appropriée',
  'settings.amountOfDeliveryObjects': 'Nombre d’objets par livraison',
  'settings.deliveryObjectBags': 'Sacs',
  'settings.supplementalService': 'Services supplémentaires',
  'settings.supplementalServiceName': 'Désignation',
  'settings.addSupplementalService': 'Ajouter service supplémentaire',
  'settings.removeSupplementalService': 'Veux-tu vraiment supprimer ce service supplémentaire ?',
  'settings.noSupplementalServiceDefined': 'Aucun service supplémentaire défini',
  'settings.companyName': 'Nom',
  'settings.companyPhone': 'Téléphone',
  'settings.companyTermsUrl': 'Lien vers les CGV',
  'settings.companyTermsUrlMustHaveHttps': 'Le lien doit commencer par http:// ou https:// ',
  'settings.companyNoLogo': 'Pas de logo',
  'settings.companyText': 'Description',
  'settings.deliveryNotifications': 'Notifications',
  'settings.deliveryNotifications.courier': 'Déclencher un message push dans l\'app Courrier à l\'arrivée d\'une commande',
  'analytics.moreStatisticsHintTitle': 'Avez-vous besoin de plus de statistiques?',
  'analytics.moreStatisticsHintText': 'Vous pouvez trouver plus de statistiques dans Looker Studio. Pour la configuration, les questions ou l\'aide à la configuration des requêtes, contactez info@viavelo.ch',
  'analytics.moreStatisticsHintButton': 'Ouvrir Looker Studio',
  'analytics.filter': 'Filtre',
  'analytics.today': 'Aujourd’hui',
  'analytics.dateRange': '{0} à {1}',
  'analytics.analyticsHelper': 'Seules les commandes HLD terminées durant la période sélectionnée sont prises en compte pour l’évaluation.',
  'analytics.retailerName': 'Lieu de dépôt',
  'analytics.street': 'Rue',
  'analytics.city': 'Lieu',
  'analytics.totalOrders': 'Commandes HLD',
  'analytics.totalOrdersHelper': 'Toutes les commandes HLD terminées',
  'analytics.totalDeliveries': 'Livraisons HLD',
  'analytics.totalDeliveriesHelper': 'Toutes les livraisons HLD terminées',
  'freeDeliveryItem.label': 'QR-Code Étiquette',
  'freeDeliveryItem.newFreeDeliveryItems': 'Nouveaux sacs gratuit',
  'freeDeliveryItem.noItems': 'Aucun sac gratuit disponible',
  'freeDeliveryItem.prefix': 'Préfixe',
  'freeDeliveryItem.minimumFieldWidth': 'Nombre de chiffres numériques',
  'freeDeliveryItem.start': 'Début de la séquence',
  'freeDeliveryItem.quantity': 'Nombre de codes'
}
